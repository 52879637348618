import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Layout from '../../components/Layout';

const LearnPage = () => (
	<Layout title='Getting Started with Space Research'>
		<h2 className='px-3 mt-3 display-5 raleway-700'>Getting Started with Space Research</h2>
		<p className='px-3 my-3'>
			Want to get involved in space research but don’t know where to start? Here we have compiled some resources
			to help you out.
		</p>

		<div className='row justify-content-between align-items-center mt-3'>
			<div className='col-md-6 col-lg-5 order-1 px-3'>
				<h3 className='mt-3 display-7 raleway-700'>More about Space</h3>
				<p className='my-3'>
					There’s more science going on in Earth’s orbit than you might think, and it’s not all planetary
					science and astrophysics! Microgravity lets us study a huge variety of scientific phenomena related
					to biology, fluid mechanics, and materials science. To learn more about the different fields that
					ASGSR scientists and students research, <Link to='/learn/resources'>click here</Link>.
				</p>
			</div>
			<div className='col-md-6 order-0 order-md-2 d-lg-flex justify-content-end'>
				<div className='col-lg-10'>
					<StaticImage
						src='../../images/learn/space.jpg'
						alt='Space.'
						layout='fullWidth'
						formats={['auto', 'webp']}
						placeholder='blurred'
						class='br'
					/>
				</div>
			</div>
		</div>

		<div className='row justify-content-between align-items-center'>
			<div className='col-md-6 order-0 d-lg-flex justify-content-start'>
				<div className='col-lg-10'>
					<StaticImage
						src='../../images/learn/research.jpg'
						alt='Man inspecting something under a microscope.'
						layout='fullWidth'
						formats={['auto', 'webp']}
						placeholder='blurred'
						class='br'
					/>
				</div>
			</div>
			<div className='col-md-6 col-lg-5 order-1 px-3'>
				<h3 className='mt-3 display-7 raleway-700'>Finding a Principal Investigator</h3>
				<p className='my-3'>
					If you’re a high school student heading to college soon, or an undergraduate who wants to apply to
					graduate programs to do space research, check out{' '}
					<a target='_blank' href='https://taskbook.nasaprs.com/Publication/index.cfm'>
						the NASA Taskbook
					</a>
					. The Taskbook search lets you find all the grants that NASA has awarded in all the fields related
					to gravitational science. Search the grants for topics you find interesting, and if you find a
					project with a recent award (past couple of years), contact the PI to see if he/she is hiring!
				</p>
			</div>
		</div>

		<div className='row justify-content-between align-items-center'>
			<div className='col-md-6 col-lg-5 px-3 order-1'>
				<h3 className='mt-3 display-7 raleway-700'>Competitions, Awards, Internships, and More</h3>
				<p className='my-3'>
					In addition to our own student competition,{' '}
					<a target='_blank' href='https://asgsr.org/souza/'>
						the Ken Souza Student Memorial Spaceflight Research Program
					</a>
					, we have compiled a list of external student opportunities such as internships, research positions,
					scholarships, and competitions.{' '}
					<Link to='opportunities'>Click here to see the list of Opportunities</Link>!
				</p>
			</div>
			<div className='col-md-6 order-0 order-md-2 d-lg-flex justify-content-end'>
				<div className='col-lg-10'>
					<StaticImage
						src='../../images/learn/satellite.jpg'
						alt='A satellite floating in space.'
						layout='fullWidth'
						formats={['auto', 'webp']}
						placeholder='blurred'
						class='br'
					/>
				</div>
			</div>
		</div>

		<div className='row justify-content-between align-items-center'>
			<div className='col-md-6 order-0 d-lg-flex justify-content-start'>
				<div className='col-lg-10'>
					<StaticImage
						src='../../images/learn/us-govt.jpg'
						alt='US flag waving over a building.'
						layout='fullWidth'
						formats={['auto', 'webp']}
						placeholder='blurred'
						class='br'
					/>
				</div>
			</div>
			<div className='col-md-6 col-lg-5 px-3 order-1'>
				<h3 className='mt-3 display-7 raleway-700'>US Govt. STEM Opportunity Database</h3>
				<p className='my-3'>
					The US government has compiled a database for opportunities for STEM students for both undergraduate
					and graduate students.{' '}
					<a target='_blank' href='https://stemundergrads.science.gov'>
						Click here to see opportunities for undergraduate students
					</a>
					.{' '}
					<a target='_blank' href='https://stemgradstudents.science.gov'>
						Click here to see the opportunities for graduate students
					</a>
					.
				</p>
			</div>
		</div>

		<div className='row justify-content-between align-items-center mb-5'>
			<div className='col-md-6 col-lg-5 px-3 order-1'>
				<h3 className='mt-3 display-7 raleway-700'>Post-Doc Resources</h3>
				<p className='my-3 mb-5'>
					If you’re a graduate student looking for post-doc fellowships, check out{' '}
					<a
						target='_blank'
						href='http://mitcommlab.mit.edu/broad/commkit/index-of-postdoc-fellowships-in-the-life-sciences/'
					>
						this list of fellowships in Life Sciences from MIT’s Broad Institute
					</a>
					, and{' '}
					<a target='_blank' href='https://npp.usra.edu/opportunities/'>
						this list of post-doc opportunities with NASA
					</a>
				</p>
			</div>
			<div className='col-md-6 order-0 order-md-2 d-lg-flex justify-content-end'>
				<div className='col-lg-10'>
					<StaticImage
						src='../../images/learn/graduate.jpg'
						alt='A girl graduating.'
						layout='fullWidth'
						formats={['auto', 'webp']}
						placeholder='blurred'
						class='br'
					/>
				</div>
			</div>
		</div>

		<div className='row justify-content-between align-items-center mb-5'>
			<div className='col-md-6 order-0 d-lg-flex justify-content-start'>
				<div className='col-lg-10'>
					<StaticImage
						src='../../images/learn/presentation.jpg'
						alt='A girl presenting to an audience.'
						layout='fullWidth'
						formats={['auto', 'webp']}
						placeholder='blurred'
						class='br'
					/>
				</div>
			</div>
			<div className='col-md-6 col-lg-5 order-1 px-3'>
				<h3 className='mt-3 display-7 raleway-700'>Presentation Skills</h3>
				<p className='my-3'>
					Giving a good presentation is an important part of being a scientist. You can find resources
					prepared by former ASGSR student officer Anjali Gupta on how to give an effective presentation!{' '}
					<a
						target='_blank'
						href='https://drive.google.com/file/d/1kT86Hs4bmtkn8Sv4IQs3dHG3Xq4grUeP/view?usp=sharing'
					>
						Click here to download the PDF file.
					</a>
				</p>
			</div>
		</div>
	</Layout>
);

export default LearnPage;
